import React, { FC } from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { useTranslation } from 'react-i18next';

import Seo from '../components/Seo/seo';
import { Layout } from '../components/Layout';
import { StaticImage } from 'gatsby-plugin-image';
import { Map } from '../components';
import { getImageByRelativePath } from '../shared/utilities';
import useWindowDimensions from '../hooks/useWindowDimensions';

const IslandsPage: FC = () => {
    const { t } = useTranslation('translation', { keyPrefix: 'islandsPage' });

    const { width } = useWindowDimensions();
    function getBanner() {
        var banner
        if (width > 768) {
            banner = <StaticImage
                className={'banner'}
                src="../images/banner4.jpg"
                quality={95}
                formats={['auto', 'webp', 'avif']}
                loading='eager'
                alt='Intro banner'
            />

        }
        else {
            banner = <StaticImage
                className={'banner'}
                src="../images/banner4_mobile.jpg"
                quality={95}
                formats={['auto', 'webp', 'avif']}
                loading='eager'
                alt='Intro banner'
            />
        }
        return banner
    }
    const banner = getBanner();

    const data: any = useStaticQuery(graphql`
    {
        Test: file(relativePath: {eq: "santiago.jpg"}) {
         childImageSharp {
           gatsbyImageData(width: 900)
         }
         relativePath
        }
         Test2: file(relativePath: {eq: "islands_bartolome.JPG"}) {
         childImageSharp {
           gatsbyImageData(width: 900)
         }
         relativePath
       }
         Test3: file(relativePath: {eq: "north_seymour.jpg"}) {
         childImageSharp {
           gatsbyImageData(width: 900)
         }
         relativePath
       }
         Test4: file(relativePath: {eq: "kicker_rock.jpg"}) {
         childImageSharp {
           gatsbyImageData(width: 900)
         }
         relativePath
       }
         Test5: file(relativePath: {eq: "espanola.jpg"}) {
         childImageSharp {
           gatsbyImageData(width: 900)
         }
         relativePath
       }
        Test6: file(relativePath: {eq: "islands_isabela.jpg"}) {
            childImageSharp {
            gatsbyImageData(width: 900)
            }
            relativePath
        }
        Test7: file(relativePath: {eq: "islands_south_plazas.jpg"}) {
            childImageSharp {
            gatsbyImageData(width: 900)
            }
            relativePath
        }
        Test8: file(relativePath: {eq: "islands_floreana.JPG"}) {
            childImageSharp {
            gatsbyImageData(width: 900)
            }
            relativePath
        }
        Test10: file(relativePath: {eq: "islands_isla_lobos.JPG"}) {
            childImageSharp {
            gatsbyImageData(width: 900)
            }
            relativePath
        }
        Test11: file(relativePath: {eq: "islands_san_cristobal.jpeg"}) {
            childImageSharp {
            gatsbyImageData(width: 900)
            }
            relativePath
        }
        Test12: file(relativePath: {eq: "islands_santa_cruz.JPG"}) {
            childImageSharp {
            gatsbyImageData(width: 900)
            }
            relativePath
        }
        Test13: file(relativePath: {eq: "islands_santa_fe.jpg"}) {
            childImageSharp {
            gatsbyImageData(width: 900)
            }
            relativePath
        }
     }
    `);

    return (
        <Layout>
            <Seo title={'De eilanden'} description={"Hoeveel eilanden telt Galapagos?"} />
            {banner}

            <div style={{
                textAlign: 'center',
                position: 'relative',
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                padding: '1.5rem 0 1.5rem 0',
                boxShadow: '0 0 10px rgb(72 72 72 / 16%)',
                zIndex: '200',
                backgroundColor: '#ffffff'
            }}>
                <StaticImage
                    className='palmleaf'
                    style={{
                        position: 'absolute',
                        zIndex: '1', width: '23rem',
                        right: '0%',
                        bottom: '0',
                        opacity: '0.1',
                        transform: 'scaleX(-1)'
                    }}
                    src='../images/Palmleaf.png'
                    quality={95}
                    formats={['auto', 'webp', 'avif']}
                    loading='eager'
                    alt='Intro banner'
                />

                <div className='main_text'>
                    <h4>{t('header')}</h4>
                    <p></p>
                    {t('islandsIntro1')}
                    <p></p>
                    <p>{t('islandsIntro2')}</p>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <div className='read_more_btn' style={{ height: '50px', width: '250px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Link style={{ color: '#fff', textDecoration: 'none', width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }} to='/contact/'>{t('islandsIntroButton')}</Link>
                        </div>
                    </div>

                </div>
            </div>

            {/* TODO: Add all descriptions, multiple images? */}
            <Map
                markers={[
                    {
                        name: 'Isabela',
                        description: t('isabela'),
                        image: getImageByRelativePath('islands_isabela.jpg', data), top: 55, left: 25 // TODO: image
                    },
                    {
                        name: 'Santiago',
                        description: t('santiago'),
                        image: getImageByRelativePath('santiago.jpg', data), top: 41, left: 37
                    },
                    {
                        name: 'Bartolomé',
                        description: t('bartolome'),
                        image: getImageByRelativePath('islands_bartolome.JPG', data), top: 44, left: 45
                    },
                    {
                        name: 'North Seymour',
                        description: t('seymour'),
                        image: getImageByRelativePath('north_seymour.jpg', data), top: 48, left: 55
                    },
                    {
                        name: 'South Plazas',
                        description: t('isabela'),
                        image: getImageByRelativePath('islands_south_plazas.jpg', data), top: 55, left: 60
                    }, 
                    {
                        name: 'Santa Cruz',
                        description: t('santacruz'),
                        image: getImageByRelativePath('islands_santa_cruz.JPG', data), top: 60, left: 52
                    }, // TODO: image
                    {
                        name: 'Santa Fé',
                        description: t('santafe'),
                        image: getImageByRelativePath('islands_santa_fe.jpg', data), top: 65, left: 64
                    }, // TODO: image
                    {
                        name: 'Kicker Rock',
                        description: t('kicker'),
                        image: getImageByRelativePath('kicker_rock.jpg', data), top: 63, left: 85
                    },
                    {
                        name: 'San Cristóbal',
                        description: t('cristobal'),
                        image: getImageByRelativePath('islands_san_cristobal.jpeg', data), top: 68, left: 86
                    }, // TODO: image
                    {
                        name: 'Floréana',
                        description: t('floreana'),
                        image: getImageByRelativePath('islands_floreana.JPG', data), top: 84, left: 49
                    }, // TODO: image
                    {
                        name: 'Española',
                        description: t('espanola'),
                        image: getImageByRelativePath('espanola.jpg', data), top: 87, left: 79
                    },
                    {
                        name: 'Isla Lobos',
                        description: t('lobos'),
                        image: getImageByRelativePath('islands_isla_lobos.JPG', data), top: 66, left: 83
                    },
                ]}
            />
        </Layout>
    )
}

export default IslandsPage;